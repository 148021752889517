<template>
  <div>
    <inputEmail
      :data="email"
      :label="$t('private.email.title')"
      :help="$t('private.email.info')"
      :placeholder="$t('private.email.title')"
      :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
      :error="getError()"
      :callback="setEmail"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      email: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
        .email
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getError() {
      if (this.email || !this.$store.state.save.trySave) {
        return false;
      } else {
        return this.$t("private.email.warning");
      }
    },
    setEmail(val) {
      this.email = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      this.email = this.email.toLowerCase().trim();
      data.email = this.email;

      this.$store.commit("save/setUser", {
        username: this.$store.state.save.userData.username,
        email: data.email,
        pass: this.$store.state.save.userData.pass
      });

      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
